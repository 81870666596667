.ql-container {
  font-family: "Montserrat";
  font-size: 16px;
  color: #4e4e4e;
  text-align: justify;
}

.ql-container h2 {
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 600;
}

.ql-container h3 {
  font-family: "Montserrat";
  font-size: 1.2rem;
  font-weight: 500;
}

.ql-snow .ql-editor img {
  max-height: 320px;
  margin: auto;
}

.ql-container.ql-snow {
  border: 1px solid #e9ecef;
}

.ql-editor {
  line-height: 28px;
  text-align: justify;
}

._loading_overlay_content {
  position: absolute;
  top: 5%;
  left: 45%;
}

#previewBlog {
  /* font-family: "Inter"; */
  font-size: 1rem;
  color: #4e4e4e;
  line-height: 28px;
  text-align: justify;
}

#previewBlog ol {
  list-style-type: unset;
  padding-left: 2rem;
}

#previewBlog ul {
  list-style-type: unset;
  padding-left: 2rem;
}

#previewBlog h1 {
  font-size: 1.8rem;
  font-weight: 600;
}

#previewBlog h2 {
  font-size: 1.4rem;
  font-weight: 600;
}

#previewBlog a {
  color: rgb(29, 180, 239);
}

#previewBlog img {
  max-height: 256px;
  margin: auto;
}

.ql-snow .ql-formats button:hover {
  background-color: black;
  border-radius: 8px;
}

.ql-snow .ql-picker-label:hover {
  background-color: black;
  border-radius: 8px;
}

.ql-snow .ql-picker-label:hover button:hover .ql-stroke {
  stroke: white !important;
}

.ql-snow .ql-formats button:hover .ql-stroke {
  stroke: white !important;
}

.ql-snow .ql-picker-label:hover {
  color: white !important;
}

.ql-snow .ql-formats button:hover .ql-fill {
  fill: white !important;
}